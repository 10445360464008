<template>
    <OModal ref="copyObjectsDialog" >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Copy Object(s)") }}
                        </h4>
                        <div v-if="isExecutingProc" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">
                    <p>{{$t("When objects are copied the copies will have a link back to the source object. A typical scenario will be to copy an asset master object into a project.")}}</p> 
                    <div class="mb-2 mt-2" style="font-weight:600;font-size:smaller" v-if="vSelCount>=0">
                        {{ vSelCount }} {{$t(" objects selected")}}
                    </div>
                    <div style="max-width:600px">
                        {{$t("Copy to Org.unit")}}:
                        <OrgunitsLookup 
                                textInput
                                :bind="sel=>{OrgUnit_ID = sel.ID; OrgUnit = sel.Name;}"
                                class="form-control form-control-sm "
                                v-model="OrgUnit" />
                    </div>
                    <div style="max-width:600px" class="mt-1">
                        <label>
                            <input v-model="secureUniqueness" type="checkbox">
                            {{ $t("Secure uniqueness by adding _Copy as a suffix to the name field")}}
                        </label>               
                    </div>
                </div>
                            
                <div class="modal-footer">
                    <div style="display:inline;font-size:small;" class="me-2" v-if="vSelCount===0">
                        <div class="text-danger">{{ $t("No objects selected") }}</div>
                    </div>
                    <button class="btn btn-outline-primary" :disabled="vSelCount===0 || isExecutingProc" @click="copyObjects">{{$t("Copy")}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
import { ref, defineExpose, computed, watch } from 'vue';
import OModal from 'o365.vue.components.Modal.vue'; 
import { userSession } from 'o365.modules.configs.ts';
import OrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue'
import alert from 'o365.controls.alert.ts';

const OrgUnit_ID = ref();
const OrgUnit = ref();
const secureUniqueness = ref(false); 
const procSubmit = getOrCreateProcedure({ id: "procSubmit" + crypto.randomUUID(), procedureName: "astp_Complete_CopyObjects" });
const copyObjectsDialog = ref();
const isExecutingProc = ref(false);

async function copyObjects() {
    if (vSelCount.value>0) {
        const ids = await props.objectsDataObject.selectionControl.getSelectedRows();
        const preparedRows = [];
        ids.forEach(sel => {
            if (sel.ID !== null) preparedRows.push([sel.ID]);
        });
        createObjects(preparedRows);
    }
}

defineExpose({ show });

const props = defineProps({
    objectsDataObject: {
        type: Object,
        required: true
    }
});


const vSelCount = ref(); 

async function show() { 
    vSelCount.value = -1;
    loadRecordCount();
    copyObjectsDialog.value?.show();
}

async function loadRecordCount() { 
    var vArr = await props.objectsDataObject.selectionControl.getSelectedRows();
    vSelCount.value = vArr.length;
    console.log(vSelCount.value)
}

async function createObjects(ids) {
    isExecutingProc.value = true; 
    procSubmit.timeout = 360;
    await procSubmit.execute({
        Objects: ids,
        NewOrgUnit_ID : OrgUnit_ID.value,
        Suffix : (secureUniqueness.value ? "_Copy" : "")
    }).then(
        (response) => {
            if(response.Table.length>0) {
                var vMessage = $t("Rows copied:") + " " + response.Table[0].RowsAffected;                
                alert(vMessage, 'success', {autohide: true, delay: 3000});                
                copyObjectsDialog.value.hide();
                props.objectsDataObject.load();
            } else {
                alert($t('No objects copied.'), 'danger', { autohide: true, delay: 3000});
            }
        }
    ).finally(() => {
        isExecutingProc.value = false;
    });    
}

</script>